import React from "react";
import theme from "../../../assets/theme";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import CustomSelect from "../../common/CustomSelect/CustomSelectDoc";
import useLoader from "../../../hooks/useLoader";
// import { caseDocumentType } from "../../../helpers/constants";
import styled from "styled-components";

const DocChangeType = ({ rowData, MTRef }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  let docId = rowData?.id;
  let caseId = rowData?.entityId;

  const changeDocType = async (value) => {
    try {
      setLoader({ state: true, message: "Change Document..." });
      const payload = {
        uploadType: value,
      };
      const res = await CaseService.changeDocType(payload, caseId, docId);
      if (res) {
        enqueueSnackbar("Document Type Changed Successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
    }
  };

  const caseDocumentType = [
    { label: "Select Document Type", value: "", disabled: true },
    {
      label:
        "Upload Declaration of acceptance and Statement of Independence by the Arbitrator",
      value:
        "Upload Declaration of acceptance and Statement of Independence by the Arbitrator",
      color: "#0000FF",
    },
    {
      label:
        "Documents/order(s) passed by the Arbitral Tribunal in the matters relating to various applications may be filed as per the IIAC Regulations",
      value:
        "Documents/order(s) passed by the Arbitral Tribunal in the matters relating to various applications may be filed as per the IIAC Regulations",
      color: "#4B0082",
    },
    {
      label: "Various order/procedural orders passed by the Arbitral Tribunal",
      value: "Various order/procedural orders passed by the Arbitral Tribunal",
      color: "#006400",
    },
    {
      label: "Any other document",
      value: "Any other document",
      color: "#00838c",
    },
    {
      label:
        rowData?.url?.uploadType ===
        "Upload Declaration of acceptance and Statement of Independence by the Arbitrator"
          ? null
          : rowData?.url?.uploadType ===
            "Documents/order(s) passed by the Arbitral Tribunal in the matters relating to various applications may be filed as per the IIAC Regulations"
          ? null
          : rowData?.url?.uploadType ===
            "Various order/procedural orders passed by the Arbitral Tribunal"
          ? null
          : rowData?.url?.uploadType === "Any other document"
          ? null
          : `${rowData?.url?.uploadType} (Others)`,
      value: rowData?.url?.uploadType,
      disabled: true,
      color: "#5A5A5A",
    },
  ];

  return (
    <Container className="input-select">
      <CustomSelect
        menuItemValues={caseDocumentType}
        name={rowData?.url?.uploadType}
        value={rowData?.url?.uploadType}
        disabled={
          rowData?.url?.uploadType === "Arbitration Notice" ? true : false
        }
        onChange={(e) => changeDocType(e.target.value)}
      />
    </Container>
  );
};

export default DocChangeType;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;
