import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import styled from "styled-components";
import theme from "../../../assets/theme";

const Index = ({ setComment }) => {
  const [isRecording, setIsRecording] = useState(false);
  const commands = [
    {
      command: "reset",
      callback: () => resetTranscript(),
    },
    {
      command: "stop",
      callback: () => SpeechRecognition.stopListening(),
    },
  ];

  const { transcript, interimTranscript, finalTranscript, resetTranscript } =
    useSpeechRecognition({ commands });

  useEffect(() => {
    if (transcript) {
      setComment(transcript);
    }
  }, [transcript]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (finalTranscript !== "") {
      console.log("Got final result:", finalTranscript);
    }
  }, [interimTranscript, finalTranscript]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log(
      "Your browser does not support speech recognition software! Try Chrome desktop, maybe?"
    );
  }

  const listenContinuously = () => {
    setIsRecording(true);
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-GB",
    });
  };

  const resetTranscripts = () => {
    if (transcript) {
      setComment(resetTranscript);
    }
  };

  const stopListening = () => {
    setIsRecording(false);
    SpeechRecognition.stopListening();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <MicIcon onClick={resetTranscripts}>
        <ProfileImage
          src={require("../../../assets/images/JustAct Icons for Case Manager Site_V1-01.png")}
        />
      </MicIcon>
      {!isRecording && (
        <MicIconStart onClick={listenContinuously}>
          <ProfileImage
            src={require("../../../assets/images/JustAct Icons for Case Manager Site_V1-02.png")}
          />
        </MicIconStart>
      )}
      {isRecording && (
        <MicIconStop onClick={stopListening}>
          <ProfileImage
            src={require("../../../assets/images/JustAct Icons for Case Manager Site_V1-03.png")}
          />
        </MicIconStop>
      )}
    </div>
  );
};

export default Index;

const MicIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 60px;
  @media ${theme.breakpoints.sm_up} {
    width: 25%;
    height: 36px;
    margin-left: 10px;
  }
`;

const MicIconStart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 30px;
  @media ${theme.breakpoints.sm_up} {
    width: 25%;
    height: 36px;
    margin-left: 10px;
  }
`;

const MicIconStop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 30px;
  @media ${theme.breakpoints.sm_up} {
    width: 25%;
    height: 36px;
    margin-left: 10px;
  }
`;

const ProfileImage = styled.img`
  width: 42px;
  height: 35px;
  border-radius: 50%;
  object-fit: contain;
`;
