import React, { useState } from "react";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import queryString from "query-string";
import { makeStyles, Tooltip } from "@material-ui/core";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import COLORS from "../../../assets/Colors";
import { HyperLink } from "./ViewRecording";
import useLoader from "../../../hooks/useLoader";
import { navigate } from "@reach/router";
import BackArrow from "@material-ui/icons/ArrowBackSharp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const LiveTranscriptDet = ({ recordData, setLiveTranscript, type, id }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [result, setResult] = useState({});
  const { setLoader } = useLoader();
  const [expand, setExpand] = useState(true);

  async function liveTranscriptDet(id, query) {
    try {
      setLoader({ state: true, message: `Loading recordings...` });
      const response = await CaseService.getLiveTranscriptDet(id, query);
      return { ...response };
    } catch (error) {
      console.log(error);
    } finally {
      setLoader({ state: false });
    }
  }

  const downloadTranscripts = async (id) => {
    try {
      setLoader({ state: true, message: "Download transcript" });
      const payload = {
        transcriptId: id,
      };
      const response = await CaseService.downloadTranscripts(payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      window.open(response?.fileUrl);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const columns = [
    {
      field: "Id",
      title: "Id",
      sorting: false,
      render: (rowData) => (
        <div style={{ marginLeft: 30 }}>{rowData?.meetingId}</div>
      ),
      headerStyle: {
        padding: `12px 16px 12px 45px`,
      },
      cellStyle: {
        padding: `12px 16px 12px 16px`,
      },
    },
    {
      field: "audioFileUrl",
      title: "Live Transcript",
      sorting: false,
      render: (rowData, index) =>
        rowData?.audioFileName ? (
          <Tooltip title={rowData?.audioFileName} placement="top">
            <HyperLink
              style={{ width: 100 }}
              onClick={() =>
                navigate(
                  rowData?.crossExamination
                    ? `/arbitrator/cases/${id}/cross-examination-transcript?caseType=${type}`
                    : `/arbitrator/cases/${id}/transcription?caseType=${type}`,
                  {
                    state: rowData,
                  }
                )
              }
            >
              {rowData?.audioFileName}
            </HyperLink>
          </Tooltip>
        ) : (
          <div style={{ paddingLeft: "20px" }}>{"-"}</div>
        ),
      headerStyle: {
        flexWrap: "wrap",
        wordWrap: "break-word",
      },
    },
    {
      field: "timeInterval",
      title: <p>{"Transcript Interval"}</p>,
      sorting: false,
      render: (rowData) => (
        <div style={{ marginLeft: 30 }}>
          {rowData?.timeInterval ? rowData?.timeInterval : "-"}
        </div>
      ),
    },
    {
      field: "createdAt",
      title: "Created Time",
      sorting: false,
      render: (rowData) =>
        rowData?.createdAt ? (
          <b>{rowData?.createdAt}</b>
        ) : (
          <div style={{ paddingLeft: "20px" }}>{"-"}</div>
        ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "download",
      title: "Download",
      sorting: false,
      render: (rowData) => (
        <HyperLink
          style={{ width: 83 }}
          onClick={() => downloadTranscripts(rowData?.id)}
        >
          {"Download Transcript"}
        </HyperLink>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let payload = {
        page: query.page + 1,
        perPage: 10,
        recordId: recordData?.recordId,
      };
      const stringParams = `?${queryString.stringify(payload)}`;
      liveTranscriptDet(recordData?.meetingId, stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setResult(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setResult({
            data: [],
            lastPage: 1,
            page: 1,
            perPage: 10,
            total: 0,
          });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <>
      <Accordion
        className={classes.root}
        expanded={expand}
        onChange={() => (expand ? setExpand(false) : setExpand(true))}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionHeading>{"Live Transcripts"}</AccordionHeading>
          <Actions>
            <HyperLink onClick={() => setLiveTranscript(false)}>
              <BackArrow />
            </HyperLink>
          </Actions>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <CustomTable
              pluralTitle="Transcript"
              singularTitle=""
              {...{
                columns,
                data,
              }}
              noToolbar
              pageSize={10}
              hidePagination={result.lastPage === 1}
              state={result}
            />
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LiveTranscriptDet;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;
