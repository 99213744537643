import React from "react";
import { ProfileContainer } from "./Styles";
import { Center } from "../../../styles/component/style";
import { CreateProfile } from "../../internal/CreateProfile/CreateProfile";
import { Header } from "../../internal/Header/Header";
import useUser from "../../../hooks/useUser";
import useIfLoggedIn from "../../../hooks/useIfLoggedIn";

const Index = () => {
  useIfLoggedIn();

  const { userObject } = useUser();
  // if (userObject?.name) {
  return (
    <Header>
      <Center>
        <ProfileContainer>
          <CreateProfile
            message={`${userObject?.name} thanks for verifying your phone number. We need you to fill in a few more details in order to create you profile on IIAC`}
          />
        </ProfileContainer>
      </Center>
    </Header>
  );
  // } else return null;
};

export default Index;
