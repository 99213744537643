import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Checkbox, Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import useBundle from "../../../hooks/useBundle";
import { CustomInputField } from "../../common/FormInputs";
import { Formik } from "formik";

export default function RefundModal({
  openBundleModal,
  setOpenBundleModal,
  id,
  caseId,
  bundleName,
  docName,
  setRefreshDetails,
  bundleDocs,
  setDocName,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [docsInfo, setDocsInfo] = useState(bundleDocs);
  const { setBundleOpen } = useBundle();

  useEffect(() => {
    let initialArray = [];
    if (docName) {
      docName.filter((item) => {
        if (item?.checked) {
          initialArray.push(item);
        }
        return initialArray;
      });
      setDocsInfo(initialArray);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docName]);

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true, message: "Regenerate Bundle..." });
      const payload = {
        bundleName: bundleName,
        bundleDocuments: docsInfo,
        addPageNumber: values?.addPageNumber,
      };
      const res = await CaseService.updateBundle(payload, caseId, id);
      if (res) {
        enqueueSnackbar("Document Updated Successfully", {
          variant: "success",
        });
        setRefreshDetails(true);
      }
      resetForm();
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpenBundleModal(false);
      setBundleOpen({ state: false, boolean: false });
    }
  }

  const handleSelect = (event, index, checked) => {
    let isArray = [];
    const update = [...docName];
    update[index] = {
      ...docName[index],
      [checked]: event.target.checked,
    };
    setDocName(update);
    update.filter((item) => {
      if (item?.checked) {
        isArray.push(item);
      }
      setDocsInfo(isArray);
      return isArray;
    });
  };

  const handleDocChange = (event, index, pageNo) => {
    let isArray = [];
    const update = [...docName];
    update[index] = {
      ...docName[index],
      [pageNo]: event.target.value,
    };
    setDocName(update);
    update.filter((item) => {
      if (docsInfo.some((el) => el.id === item.id)) {
        isArray.push(item);
      }
      setDocsInfo(isArray);
      return isArray;
    });
  };

  const checkbox_actions = [
    {
      label: "Add Page Number",
    },
  ];

  return (
    <Modal
      open={openBundleModal}
      onClose={() => {
        setOpenBundleModal(false);
        setBundleOpen({ state: false, boolean: false });
      }}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Generate Bundles</Heading>
            <CloseModal
              onClick={() => {
                setOpenBundleModal(false);
                setBundleOpen({ state: false, boolean: false });
              }}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <Formik
            initialValues={{
              addPageNumber: false,
            }}
            enableReinitialize
            validateOnChange
            validateOnBlur
            onSubmit={onFormSubmit}
          >
            {({ values, errors, touched, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <AgentFormContainer {...{ docName }}>
                  <div className="docs-listing">
                    {docName
                      ? docName.map((item, index) => (
                          <StyledForm style={{ paddingBottom: "20px" }}>
                            <BodyContainer>
                              <CheckboxContainer style={{ marginTop: 6 }}>
                                <CustomCheckbox
                                  onChange={(ev) =>
                                    handleSelect(ev, index, "checked")
                                  }
                                  checked={item?.checked}
                                  name={item?.name + index}
                                  key={item.name}
                                />

                                <CheckBoxLabel for="secretDoc">
                                  {item.name}
                                </CheckBoxLabel>
                                <div className="bundle-doc">
                                  <Label>{"page No"}</Label>
                                  <CustomInputField
                                    className={"input-white"}
                                    variant="outlined"
                                    value={item?.pageNo}
                                    disabled={item?.checked ? false : true}
                                    onChange={(event) =>
                                      handleDocChange(event, index, "pageNo")
                                    }
                                  />
                                </div>
                              </CheckboxContainer>
                            </BodyContainer>
                          </StyledForm>
                        ))
                      : ""}
                  </div>
                  <>
                    <FormLabel>Bundles Options</FormLabel>
                    <CheckboxGrid>
                      {checkbox_actions.map((item, index) => (
                        <div>
                          <CustomCheckbox
                            name="addPageNumber"
                            value={item.addPageNumber}
                            checked={values.addPageNumber}
                            onChange={() =>
                              setFieldValue(
                                "addPageNumber",
                                !values.addPageNumber
                              )
                            }
                          />
                          <CheckBoxLabel>{item.label}</CheckBoxLabel>
                        </div>
                      ))}
                    </CheckboxGrid>
                  </>
                  <ButtonContainer>
                    <PrimaryOutlinedCTAButton
                      style={{ width: "45%" }}
                      onClick={() => setOpenBundleModal(false)}
                    >
                      Cancel
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      style={{ width: "45%" }}
                      disabled={!docsInfo?.length}
                      onClick={handleSubmit}
                    >
                      Submit
                    </PrimaryCTAButton>
                  </ButtonContainer>
                </AgentFormContainer>
              </form>
            )}
          </Formik>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomCheckbox = ({ ...props }) => (
  <Checkbox
    color={"primary"}
    icon={
      <img src={require("../../../assets/images/checkbox.svg")} alt="check" />
    }
    checkedIcon={
      <img
        src={require("../../../assets/images/checkBoxSelected.svg")}
        alt="check"
      />
    }
    {...props}
  />
);

const CheckBoxLabel = styled.label`
  font-size: 16px;
  width: 350px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  & .docs-listing {
    backface-visibility: hidden;
    height: ${({ docName }) => (docName?.length > 3 ? "360px" : "auto")};
    overflow: auto;
    z-index: -1px;
    overflow-x: hidden;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 50%;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 40px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 6px;
  color: ${COLORS.COLOR_DARK};
  text-align: left;
  & span {
    padding: 0px;
  }
`;

const BodyContainer = styled.div`
  width: 98%;
  border-radius: 10px;
  background-color: white;
  border: solid 0.5px #e1e3ee;
  padding: 4px 5px 14px 5px;
`;

const StyledForm = styled.div``;

const FormLabel = styled.div`
  display: flex;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  margin-top: 18px;
  color: ${COLORS.INPUT_LABEL};
  margin-left: 12px;
`;

const CheckboxGrid = styled.div`
  width: 85%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0px;
`;
