import labels from "../../../helpers/labels.json";

export const sidebar_options = [
  {
    icon: require("../../../assets/images/my_cases.svg"),
    inactive: require("../../../assets/images/my_cases.svg"),
    title: labels.my_cases,
    path: "/arbitrator/cases",
    listItems: [
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        title: labels.my_cases_arbitration,
        path: "/arbitrator/cases?caseType=arbitration",
      },
    ],
  },
  {
    icon: require("../../../assets/images/upload.svg"),
    inactive: require("../../../assets/images/upload.svg"),
    title: labels.upload,
    path: "/arbitrator/uploads",
  },
  {
    icon: require("../../../assets/images/profileInactive.svg"),
    inactive: require("../../../assets/images/profileInactive.svg"),
    title: labels.order_dictation,
    path: "/arbitrator/order-dictation",
  },
  {
    icon: require("../../../assets/images/my_cases.svg"),
    inactive: require("../../../assets/images/my_cases.svg"),
    title: labels.daily_casuse,
    path: "/arbitrator/daily-cause",
  },
];
