import React, { useRef, useState } from "react";
import {
  CardWrapper,
  HeadingContainer,
  Header3,
} from "../../../styles/component/style";
import { MessageContainer, InputContainer } from "./Styles";
import styled from "styled-components";
import { FieldArray, Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import theme from "../../../assets/theme";
import { profileCompletionValidationSchema } from "../../../helpers/validationSchema";
import { PrimaryCTAButton } from "../../common/Buttons";
import _ from "lodash";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import useLoader from "../../../hooks/useLoader";
import useUser from "../../../hooks/useUser";
import { dataURLtoFile, getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";
import { ProfilePicContainer } from "../../pages/MyProfile/styles";
import { ImageWrapper } from "../DrawerTable";
import AWSService from "../../../services/AWSService";
import { CustomInputField } from "../../common/FormInputs";
import COLORS from "../../../assets/Colors";
import { FormLabel } from "@material-ui/core";
import { Fragment } from "react";
import ImageCropper from "../../common/ImageCropper";

export const CreateProfile = ({
  heading = labels["onboarding.step3"],
  message = `Avian thanks for verifying your phone number. We need you to fill in a few more details in order to create you profile on IIAC`,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { userObject, setUserObject } = useUser();
  const fileRef = useRef();
  const [preCropImage, setPreCropImage] = useState("");
  const [openCropperModal, setOpenCropperModal] = useState(false);
  const [fileType, setFileType] = useState("");

  const inputs = [
    {
      name: "name",
      label: labels["onboarding.fullname"],
      type: "text",
      required: true,
    },
    {
      name: "tagLine",
      label: labels["onboarding.tagLine"],
      type: "text",
      required: true,
    },
    {
      name: "qualification",
      label: labels["onboarding.qualification"],
      type: "text",
      required: true,
    },
    {
      name: "experience",
      label: labels["onboarding.yearsOfExperience"],
      type: "text",
      required: true,
    },
    {
      name: "languagesKnown",
      label: labels["onboarding.language"],
      type: "text",
      required: true,
    },
    {
      name: "location",
      label: labels["onboarding.location"],
      type: "text",
      required: true,
    },
    {
      name: "affiliatedOrg",
      label: labels["onboarding.affiliatedOrg"],
      type: "text",
      required: true,
    },
    {
      name: "alternateMobileNumber",
      label: labels["onboarding.alternateMobileNumber"],
      type: "text",
      required: false,
    },
    {
      name: "institutionOfMediationTraining",
      label: labels["onboarding.institutionOfMediationTraining"],
      type: "text",
      required: false,
    },
    {
      name: "numberOfMediationsConducted",
      label: labels["onboarding.numberOfMediationsConducted"],
      type: "text",
      required: false,
    },
    {
      name: "lastHeldPosition",
      label: labels["onboard.lastHeldPosition"],
      type: "text",
      required: true,
    },
  ];

  async function onFormSubmit(values) {
    const postData = JSON.parse(JSON.stringify(values));
    try {
      setLoader({ state: true, message: "Submitting form..." });
      let payload = _.pick(postData, [
        "name",
        "tagLine",
        "qualification",
        "experience",
        "languagesKnown",
        "location",
        "affiliatedOrg",
        "specialities",
        "shortBio",
        "avatarUrl",
        "alternateMobileNumber",
        "institutionOfMediationTraining",
        "numberOfMediationsConducted",
        "lastHeldPosition",
      ]);
      const response = await AuthService.profileUpdate(payload);
      if (response) {
        if (response?.me?.emailVerified) {
          navigate("/arbitrator/welcome");
          setUserObject(response?.me);
        } else {
          enqueueSnackbar(
            "Verification mail sent successfully. Please check your Junk Folder/ Promotions Folder incase you don't receive the email",
            {
              variant: "success",
            },
          );
          navigate("/verify-email", {
            state: {
              payload,
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file.type.includes("image/")) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
        }
      } catch (error) {
        setFieldError(field, "Could not upload this file");
      } finally {
        setLoader({ state: false });
        setOpenCropperModal(!openCropperModal);
      }
    } else {
      return enqueueSnackbar("Invalid File Type", {
        variant: "error",
      });
    }
  }

  const cropImageData = (file) => {
    if (file.type.includes("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreCropImage(reader.result);
      };
      setOpenCropperModal(!openCropperModal);
      setFileType(file.type);
      reader.readAsDataURL(file);
    } else {
      return enqueueSnackbar("Invalid File Type", {
        variant: "error",
      });
    }
  };

  const uploadCroppedDate = (cropData, setFieldValue, setFieldError) => {
    const file = dataURLtoFile(cropData, fileType);
    s3Upload(file, "avatarUrl", setFieldValue, setFieldError);
  };

  return (
    <CardWrapper>
      <HeadingContainer>
        <Header3>{heading}</Header3>
      </HeadingContainer>
      <MessageContainer>{message}</MessageContainer>
      <InputContainer>
        <Formik
          initialValues={{
            avatarUrl: userObject?.avatarUrl || "",
            name: userObject?.name || "",
            tagLine: userObject?.tagLine || "",
            qualification: userObject?.qualification || "",
            experience: userObject?.experience || "",
            languagesKnown: userObject?.languagesKnown || " ",
            location: userObject?.location || "",
            affiliatedOrg: userObject?.affiliatedOrg || "",
            specialities: userObject?.specialities || [""],
            shortBio: userObject?.shortBio || "",
            alternateMobileNumber: userObject?.alternateMobileNumber || "",
            institutionOfMediationTraining:
              userObject?.institutionOfMediationTraining || "",
            numberOfMediationsConducted:
              userObject?.numberOfMediationsConducted || "",
            lastHeldPosition: userObject?.lastHeldPosition || "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={profileCompletionValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            setFieldError,
            setFieldTouched,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <ProfilePicContainer style={{ marginTop: 30 }}>
                <ImageWrapper
                  onClick={() => fileRef.current.click()}
                  style={{ position: "relative" }}
                >
                  <ProfilePic
                    src={
                      values?.avatarUrl
                        ? values.avatarUrl
                        : require("../../../assets/images/userPlaceholder.svg")
                    }
                  ></ProfilePic>
                  <PickerIcon
                    style={{ bottom: 10 }}
                    src={require("../../../assets/images/imagePicker.svg")}
                  />
                </ImageWrapper>
              </ProfilePicContainer>

              <div style={{ marginTop: 30 }}>
                {inputs.map((input, index) => {
                  return (
                    <FormField
                      key={index}
                      disabled={input?.name === "name"}
                      {...{
                        touched,
                        errors,
                        input,
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                  );
                })}
              </div>
              <div>
                <FieldArray
                  name="specialities"
                  render={(arrayHelpers) => (
                    <div
                      style={{
                        marginTop: 6,
                      }}
                    >
                      {values?.specialities?.length &&
                        values?.specialities?.map((speciality, index) => (
                          <Fragment key={index}>
                            <FormLabel
                              style={{
                                fontFamily: theme.fonts.primaryFontSemiBold,
                                fontSize: 12,
                                color: COLORS.INPUT_LABEL,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ display: "flex", flex: 1 }}>
                                {labels["onboarding.specialities"]}
                                <span className="required-star"> *</span>
                              </div>
                              {values?.specialities?.length > 1 ? (
                                <div>
                                  <img
                                    onClick={() => arrayHelpers.remove(index)}
                                    alt="delete"
                                    style={{
                                      height: 16,
                                      width: 16,
                                      cursor: "pointer",
                                    }}
                                    src={require("../../../assets/images/removeIcon.svg")}
                                  />
                                </div>
                              ) : null}
                            </FormLabel>
                            <CustomInputField
                              type={"text"}
                              value={speciality}
                              error={
                                errors.specialities?.length &&
                                touched.specialities?.length &&
                                errors?.specialities[index] &&
                                touched?.specialities[index]
                              }
                              helperText={
                                errors.specialities?.length &&
                                touched.specialities?.length &&
                                touched?.specialities[index] &&
                                errors?.specialities[index]
                              }
                              id={`specialities[${index}]`}
                              name={`specialities[${index}]`}
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                marginTop: 6,
                                marginBottom: 20,
                              }}
                            />
                          </Fragment>
                        ))}
                      <AddButton
                        type="button"
                        onClick={() => {
                          const index = values?.specialities?.findIndex(
                            (el) => !el.trim(),
                          );
                          if (index !== -1) {
                            setFieldTouched(`specialities[${index}]`, true);
                          } else {
                            arrayHelpers.push("");
                          }
                        }}
                      >
                        + Add another specialities
                      </AddButton>
                    </div>
                  )}
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <FormField
                  maxLength={0}
                  input={{
                    type: "textarea",
                    name: "shortBio",
                    label: labels["onboarding.shortbio"],
                  }}
                  {...{
                    values,
                    handleChange,
                    errors,
                    handleSubmit,
                    touched,
                    handleBlur,
                    setFieldValue,
                  }}
                />
              </div>

              <input
                ref={fileRef}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(e) => {
                  cropImageData(
                    e.target.files[0],
                    "avatarUrl",
                    setFieldValue,
                    setFieldError,
                  );
                }}
              />
              <ButtonContainer>
                <PrimaryCTAButton onClick={handleSubmit}>
                  {labels["onboarding.createProfile"]}
                </PrimaryCTAButton>
              </ButtonContainer>
              <ImageCropper
                {...{
                  openCropperModal,
                  setOpenCropperModal,
                  preCropImage,
                  setPreCropImage,
                }}
                onCropped={(data) =>
                  uploadCroppedDate(data, setFieldValue, setFieldError)
                }
              />
            </FormContainer>
          )}
        </Formik>
      </InputContainer>
    </CardWrapper>
  );
};

const FormContainer = styled.form``;

const ButtonContainer = styled.div`
  margin: 50px auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const PickerIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 34px;
  height: 34px;
  object-fit: contain;
`;

const ProfilePic = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 2px;
  position: relative;
`;

export const AddButton = styled.button`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #00838c;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
