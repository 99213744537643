/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
  SmallText,
  SmallTextLogin,
  Center,
} from "../../../styles/component/style";
import { PrimaryCTAButton } from "../Buttons";
import { Formik } from "formik";
import { signupValidationSchema } from "../../../helpers/validationSchema";
import FormField from "../FormField/FormField";
import _ from "lodash";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { navigate, Link } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";
import useLoader from "../../../hooks/useLoader";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import GLogin from "../GoogleLogin";

export const Signup = ({
  heading = "SignUp",
  inputs = [],
  tcLink = "",
  submitLabel = labels["proceed"],
  loginLink = "",
  disableSubmit = false,
  location,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { state } = location;
  const [params, setParams] = useState("");
  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Please wait..." });
      let payload = _.pick(values, ["email", "mobile", "password"]);
      payload.name = values.fullname;
      const response = await AuthService.verifications(payload);
      if (response && payload.mobile && payload.mobile.includes("+91")) {
        enqueueSnackbar("OTP Sent Successfully", {
          variant: "success",
        });
        navigate("/otp", {
          state: {
            payload,
            values,
          },
        });
      } else {
        if (response) {
          navigate("/create-profile");
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  useEffect(() => {
    if (location?.search) {
      const payload = queryString.parse(location.search);
      if (payload) {
        setParams(payload);
      }
    }
  }, [location]);

  const signUpwithGoogle = (res) => {
    if (res?.tokenObj?.id_token) {
      navigate("/google-signup", {
        state: {
          idToken: res?.tokenObj?.id_token,
          profileObj: res?.profileObj,
        },
      });
    }
  };

  const disableInputs = (input) => {
    return params?.email?.length && input.type === "email";
  };

  return (
    <Formik
      initialValues={{
        fullname: params?.name ? params?.name : state?.payload?.fullname || "",
        email: params?.email ? params?.email : state?.payload?.email || "",
        mobile: params?.mobile ? params?.mobile : state?.payload?.mobile || "",
        password: state?.payload?.password || "",
        confirmPassword: state?.payload?.confirmPassword || "",
      }}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={signupValidationSchema}
      validateOnBlur
      validateOnChange
    >
      {({
        values,
        handleChange,
        errors,
        handleSubmit,
        touched,
        handleBlur,
        setFieldValue,
      }) => (
        <CardWrapper onSubmit={handleSubmit}>
          <HeadingContainer>
            <Header3>{heading}</Header3>
          </HeadingContainer>
          <ContentContainer>
            {inputs.map((input, index) => {
              return (
                <FormField
                  disabled={disableInputs(input)}
                  key={index}
                  {...{
                    touched,
                    errors,
                    input,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }}
                />
              );
            })}
          </ContentContainer>
          {tcLink && (
            <div className="pt20 pb10 center">
              <SmallText>
                By signing up, I agree with all{" "}
                <a
                  href={`${process.env.REACT_APP_USER_BASE_URL}/terms-and-conditions`}
                  target="_blank"
                >
                  Terms and Conditions
                </a>{" "}
                and IIAC{" "}
                <a
                  href={require("../../../assets/IIAC_Rules.pdf")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  rules{" "}
                </a>
                for Arbitration, as applicable.
              </SmallText>
            </div>
          )}
          <div className="pt20 pb10">
            <PrimaryCTAButton
              type="submit"
              onClick={handleSubmit}
              disabled={disableSubmit}
            >
              {submitLabel}
            </PrimaryCTAButton>
          </div>

          {loginLink && (
            <div className="center">
              <SmallTextLogin>
                Have an account already? <Link to={"/"}>Login here</Link>
              </SmallTextLogin>
            </div>
          )}
          <Center className="openSans-SemiBold pt10 pb10">Or</Center>
          <div className="pb20">
            <GLogin
              label="Signup with Google"
              className="p8"
              onSuccess={signUpwithGoogle}
            />
          </div>
        </CardWrapper>
      )}
    </Formik>
  );
};
