/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { states } from "./constants";
import { isValidPhoneNumber } from "libphonenumber-js";

function validatePinCodeByState(state, pin) {
  if (state && pin) {
    const found = states.find((st) => st.value === state);
    const pinStart = pin?.toString().substring(0, 2);
    if (found && found.pinStart.includes(parseInt(pinStart, 10))) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const PAN_CARD_REGEX = (input) => {
  // Ensure the input is exactly 10 characters long
  if (input.length !== 10) {
    return false;
  }

  // Check the first 5 characters are letters (case-insensitive)
  if (!/^[a-zA-Z]{5}/.test(input)) {
    return false;
  }

  // Check the next 4 characters are digits
  if (!/^[0-9]{4}/.test(input.substring(5, 9))) {
    return false;
  }

  // Check the last character is a letter (case-insensitive)
  if (!/^[a-zA-Z]$/.test(input[9])) {
    return false;
  }
  return true;
};

const GST_VALIDATION = (input) => {
  if (input.length !== 13) {
    return false;
  }

  // Check for the pattern: \d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}Z[A-Z\d]{1}
  return /^\d{2}[A-Z]{5}\d{4}[A-Z][A-Z\d]Z[A-Z\d]$/.test(input);
};

const validatePassword = (password) => {
  if (
    password.length < 8 ||
    !/[A-Z]/.test(password) ||
    !/[a-z]/.test(password) ||
    !/[0-9]/.test(password) ||
    !/[@#$%^&+*!=]/.test(password)
  ) {
    return false;
  }
  return true;
};

// const PAN_CARD_REGEX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const VOTER_REGEX = /^([a-zA-Z]){3}([0-9]){7}?$/;
const AADHAAR_REGEX_TWELVE = /^\d{4}\d{4}\d{4}$/;
const AADHAAR_REGEX_SIXTEEN = /^\d{4}\d{4}\d{4}\d{4}$/;
const CIN_REGEX = /^([LU])(\d{5})([A-Za-z]{2})(\d{4})([A-Za-z]{3})(\d{6})$/;
const MOBILE_REGEX =
  /^(\+\d{1,2})?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(x\d+)?$/;
// const PASSWORD_RULE = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
// const GST_VALIDATION = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

export const signupValidationSchema = Yup.object({
  fullname: Yup.string().trim().required("Full Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required"),
  mobile: Yup.string("Please enter valid phone number")
    // .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required")
    .test("mobile", "Please enter valid phone number", async function (value) {
      if (!isValidPhoneNumber(String(value), "IN")) {
        return false;
      } else {
        return true;
      }
    }),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be minimum 8 characters")
    .test(
      "password",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      }
    ),
  // password: Yup.string()
  //   .matches(
  //     PASSWORD_RULE,
  //     "Password should have a minimum of 1 digit and 1 letter"
  //   )
  //   .min(6, "Password must be minimum 6 characters")
  //   .required("Password is required"),
  // confirmPassword: Yup.string()
  //   .required("Confirm Password is required")
  //   .test("passwords-match", "Passwords do not match", function (value) {
  //     return this.parent.password === value;
  //   }),
});

export const signupWithGoogle = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
});

export const profileCompletionValidationSchema = Yup.object({
  name: Yup.string().nullable().required("Fullname is required"),
  tagLine: Yup.string().trim().required("Tagling is required"),
  qualification: Yup.string().trim().required("Qualification is required"),
  experience: Yup.string().trim().required("Experience is required"),
  languagesKnown: Yup.string().trim().required("Languages is required"),
  location: Yup.string().trim().required("Location is required"),
  affiliatedOrg: Yup.string()
    .trim()
    .required("Affiliated Organization is required"),
  specialities: Yup.array().of(
    Yup.string().trim().required("Speciality is Required")
  ),
  shortBio: Yup.string().trim().required("Short bio is required"),
  lastHeldPosition: Yup.string()
    .trim()
    .required("Last Held Position is required"),
});

/**Commented for netural profile creation changes */
// export const profileCompletionValidationSchema = Yup.object({
//   dob: Yup.date().nullable().required("Date of Birth is required"),
//   address_line: Yup.string()
//     .trim()
//     // .max(20, "Address is too long")
//     .required("Address is required"),
//   locality: Yup.string()
//     .trim()
//     // .max(20, "Locality is too long")
//     .required("Locality is required"),
//   city: Yup.string()
//     .trim()
//     // .max(20, "City is too long")
//     .required("City is required"),
//   state: Yup.string().trim().required("State is required"),
//   pin: Yup.number()
//     .required("Pincode is required")
//     .test("pincode", "Invalid Pincode", async function (value) {
//       if (value?.toString()?.length === 6) {
//         return validatePinCodeByState(this.parent.state, value);
//       } else {
//         return false;
//       }
//     }),
//   landmark: Yup.string()
//     .trim()
//     // .max(30, "Landmark is too long")
//     .required("Landmark is required"),
// });

export const LoginEmailValidationSchema = Yup.object({
  email: Yup.string().email("Please enter valid email").required(),
  password: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Password is required"),
});

export const LoginMobileValidationSchema = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    // .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required")
    .test("mobile", "Please enter valid phone number", async function (value) {
      if (!isValidPhoneNumber(String(value), "IN")) {
        return false;
      } else {
        return true;
      }
    }),
});

export const createPartyValidationSchema = Yup.object({
  document_house_no: Yup.string()
    .trim()
    // .max(20, "Address is too long")
    .required("Address is required"),
  document_locality: Yup.string()
    .trim()
    // .max(20, "Locality is too long")
    .required("Locality is required"),
  document_city: Yup.string()
    .trim()
    // .max(20, "City is too long")
    .required("City is required"),
  document_state: Yup.string().trim().required("State is required"),
  document_pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "Invalid Pincode", async function (value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.document_state, value);
      } else {
        return false;
      }
    }),
  document_landmark: Yup.string()
    .trim()
    .nullable()
    // .max(30, "Landmark is too long")
    .required("Landmark is required"),
  document_uri: Yup.string().trim().nullable().required("Document is required"),
  doc_number: Yup.string()
    .trim()
    .test("document-number", "", function (value) {
      if (this?.parent?.document_kind === "pan") {
        const matched = value ? PAN_CARD_REGEX(value) : false;
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "PAN Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Enter a valid PAN Number",
            path: "doc_number",
          });
        }
      } else if (this?.parent?.document_kind === "aadhaar") {
        const matched =
          value?.match(AADHAAR_REGEX_TWELVE) ||
          value?.match(AADHAAR_REGEX_SIXTEEN);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Aadhaar Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Enter a valid Aadhaar Number",
            path: "doc_number",
          });
        }
      } else if (this?.parent?.document_kind === "cin") {
        const matched = value?.match(CIN_REGEX);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "CIN Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Enter a valid CIN Number",
            path: "doc_number",
          });
        }
      } else if (this?.parent?.document_kind === "voter") {
        const matched = value?.match(VOTER_REGEX);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Voter ID Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Enter a valid Voter ID Number",
            path: "doc_number",
          });
        }
      } else if (this?.parent?.document_kind === "passport") {
        if (value) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Passport Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Passport number is required",
            path: "doc_number",
          });
        }
      }
    })
    .nullable(),
  name: Yup.string().trim().required("Party Name is required"),
  gstNumber: Yup.string()
    .trim()
    .nullable()
    .test("gstNumber", "Please enter valid GST number", async function (value) {
      if (value?.toString()?.length) {
        return GST_VALIDATION(value);
      } else {
        return true;
      }
    }),
  gstCertUrl: Yup.string().trim().nullable(),
});

export const AddAgentValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Please enter valid email")
    .required("Agent email is required"),
  pan: Yup.string().test(
    "pan",
    "Enter a valid PAN number",
    async function (value) {
      if (value?.toString()?.length) {
        return PAN_CARD_REGEX(value);
      } else {
        return true;
      }
    }
  ),
  // pan: Yup.string().matches(PAN_CARD_REGEX, "Enter a valid PAN number"),
  // .required("Agent PAN Number is required"),
  uri: Yup.string().trim().required("Authorisation Letter is required"),
});

export const CreateCaseStep1Schema = Yup.object({
  partyId: Yup.string().required("Party is required"),
  title: Yup.string().trim().required("Case Title is required"),
  resolutionKind: Yup.string(),
  description: Yup.string().test(
    "desc-test",
    "Case Description is required",
    function (value) {
      if (this?.parent?.description_document_url || value?.trim()?.length) {
        return true;
      } else {
        return false;
      }
    }
  ),
  totalClaimValue: Yup.string()
    .test("not-zero", "Total Claim value is required", function (value) {
      if (this?.parent?.resolutionKind !== "mediation") {
        return true;
      } else if (value) {
        if (Number(value.toString()?.split(",")?.join(""))) {
          return true;
        }
      }
      return false;
    })
    .typeError("Claim value cannot be empty"),
  description_document_url: Yup.string().test(
    "desc-test",
    "Case Description Document is required",
    function (value) {
      if (this?.parent?.description || value) {
        return true;
      } else {
        return false;
      }
    }
  ),
  agreement_urls: Yup.array(Yup.string()),
});

export const CaseRequestSchema = Yup.object({
  partyId: Yup.string().trim().required("Party is Required"),
});

export const CreateCaseStep2Schema = Yup.object({
  respondentKind: Yup.string().trim().required("Respondent kind is required"),
  // respondentName: Yup.object().test(
  //   "value",
  //   "Respondent name is required",
  //   function (data) {
  //     return data?.respondentName?.trim();
  //   }
  // ),
  respondentName: Yup.string().trim().required("Respondent name is required"),
  respondentEmail: Yup.string()
    .email("Please enter a valid email")
    .required("Respondent email is required"),
  // respondentPanNumber: Yup.string().matches(
  //   PAN_CARD_REGEX,
  //   "Please enter a valid PAN card"
  // ),
  respondentPanNumber: Yup.string().test(
    "respondentPanNumber",
    "Enter a valid PAN number",
    async function (value) {
      if (value?.toString()?.length) {
        return PAN_CARD_REGEX(value);
      } else {
        return true;
      }
    }
  ),
  respondentMobile: Yup.string()
    .max(10, "Enter a valid mobile number")
    .min(10, "Enter a valid mobile number"),
});

export const CreateCaseStep3Schema = Yup.object({
  claim_items: Yup.array(
    Yup.object({
      title: Yup.string().trim().nullable().required("Title is required"),
      claimValue: Yup.string()
        .test(
          "not-zero",
          "Claim value must be greater than zero",
          function (value) {
            return value;
          }
        )
        .typeError("Claim value cannot be empty"),
      unit: Yup.string().trim().nullable().required("Unit is required"),
      description_document_url: Yup.string().trim().nullable(),
      description: Yup.string().trim().nullable(),
    })
  ),
});

export const EditProfileSchema = Yup.object({
  tagLine: Yup.string().trim().required("Tagling is required"),
  qualification: Yup.string().trim().required("Qualification is required"),
  experience: Yup.string().trim().required("Experience is required"),
  languagesKnown: Yup.string().trim().required("Languages is required"),
  location: Yup.string().trim().required("Location is required"),
  affiliatedOrg: Yup.string()
    .trim()
    .required("Affiliated Organization is required"),
  specialities: Yup.array().of(
    Yup.string().trim().required("Speciality is Required")
  ),
  shortBio: Yup.string().trim().required("Short bio is required"),
  lastHeldPosition: Yup.string()
    .trim()
    .required("Last Held Position is required"),
});

export const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Please enter 8 characters")
    .test(
      "password",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      }
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.password === value;
    }),
});

export const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Current Password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password must be minimum 8 characters")
    .test(
      "newPassword",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      }
    ),
  // newPassword: Yup.string()
  //   .matches(
  //     PASSWORD_RULE,
  //     "Password should have a minimum of 1 digit and 1 letter"
  //   )
  //   .min(6, "Please enter 6 characters")
  //   .required("New Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm New Password is required")
    .min(6, "Password must be minimum 6 characters")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export const BulkUploadSchema = Yup.object({
  partyId: Yup.string().trim().required("Party is required"),
  url: Yup.string().required("Case file is required"),
});

export const DiscussFormValidationSchema = Yup.object({
  name: Yup.string().trim().required("Name is required"),
  email: Yup.string()
    .trim()
    .email("Please enter valid email")
    .required("Email is required"),
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
});

export const UploadAgreementValidationSchema = Yup.object({
  agreement_url: Yup.string()
    .trim()
    .required("Agreement Document is mandatory"),
});

export const UploadSummaryValidationSchema = Yup.object({
  summary: Yup.string().trim().required("Agreement Summary is mandatory"),
});

export const AddHearingDateValidation = Yup.object({
  startDate: Yup.string().nullable(),
  startTime: Yup.string()
    .nullable()
    .trim()
    .test("startTime", "", function (value) {
      if (this?.parent?.startDate) {
        const matched = value?.startDate;
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Time is required",
              path: "startTime",
            });
        }
      }
    }),
});
